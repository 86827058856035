<template>
    <div :id="comp.settings.id" class="c-quote">{{ comp.settings.txt }}</div>
</template>

<script>
  import { parallaxItem } from '@/mixins/parallax'
  export default {
    name: 'Comp_quote',
    mixins: [parallaxItem],
    props: {
      comp: Object
    },

    data () {
      return {
        parallax: {
          item: `#${this.comp.settings.id}`,
          triggerElement: `#${this.comp.settings.id}`,
          animTo: '-15%'
        }
      }
    }
  }
</script>
